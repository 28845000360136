@tailwind base;
@tailwind components;
@tailwind utilities;

.hexagon {
    cursor: pointer;
    -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
}

.found-words-grid {
    grid-template-rows: repeat(20, 20px);
    gap: 2px;
    grid-auto-flow: column;
}

@keyframes blink {
    to {
        visibility: hidden;
    }
}

@keyframes wiggle {
    0% { transform: translateX(0); }
   10% { transform: translateX(5px); }
   30% { transform: translateX(-5px); }
  40% { transform: translateX(0); }
}

@keyframes dropIn {
    0% { transform: translateY(-5px); opacity: 0 }
   20% { transform: translateY(0px); opacity: 1 }
   70% { transform: translateY(0px); opacity: 1 }
   100% { transform: translateY(-5px); opacity: 0 }
}